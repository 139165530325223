import * as React from "react";
import BoxContainer from "components/BoxContainer";
import Text from "components/Text";
import IsoCertified from "components/IsoCertified";
import iconResolver from "utils/icon-resolver";
import { useState } from "react";
import { useEffect } from "react";

const CertificationSamples = ({ data }) => {
  const [cleanedDescription, setCleanedDescription] = useState("");

  useEffect(() => {
    const cleanDescription = (description) => {
   
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = description;

    
      const paragraphs = tempDiv.getElementsByTagName("p");
      Array.from(paragraphs).forEach((p) => {
        if (p.innerHTML.trim() === "&nbsp;" || p.innerHTML.trim() === "") {
          p.remove();
        }
      });

  
      setCleanedDescription(tempDiv.innerHTML);
    };

 
    if (data?.description) {
      cleanDescription(data.description);
    }
  }, [data.description]);

  return (
    <>
      <BoxContainer background="bg-white" className="items-center py-8">
        <div className="mt-8 md:w-10/12 2xl:max-w-6xl m-auto">
          <div className="md:grid grid-cols-2 items-center">
            {data.imgurl ? (
              <div className="hidden md:block md:py-12 pr-8">
                <img
                  src={data?.imgurl}
                  alt="business"
                  className="rounded-[4px]"
                />
              </div>
            ) : (
              <div className="max-w-[531px] flex flex-col items-center sm:block">
                <div className="bg-yellow rounded-full p-3 inline-block mb-3">
                  <span className="w-9 h-9">{iconResolver(data.icon)}</span>
                </div>
                <Text
                  highlightedText={data?.highlightWords}
                  highlightedColor={data?.highlightedColor}
                  as="h2"
                  className="mb-16 text-3xl2 text-midBlue font-secondary font-semibold text-center sm:text-left"
                >
                  {data?.title}
                </Text>
              </div>
            )}
            <div className="flex flex-col pl-5">
              <div className="pb-12">
                {data.imgurl && (
                  <Text
                    className="font-semibold text-3xl2 text-midBlue font-secondary text-center sm:text-left"
                    as="h2"
                    highlightedText={data.highlightWords}
                    highlightedColor={data.highlightedColor}
                  >
                    {data?.title}
                  </Text>
                )}
                <Text
                  className={`mb-8 ${data.imgurl ? 'md:mb-0 mt-8' : "mt-0"} text-midBlue leading-8 font-opensans text-lg text-center sm:text-left`}
                  as="div"
                >
                 
                  <div dangerouslySetInnerHTML={{ __html: cleanedDescription }} />
                </Text>
              </div>
            </div>
          </div>
        </div>
      </BoxContainer>
      {data.isoCertifiedData_status && (
        <IsoCertified
          data={data.isoCertifiedData}
          background="bg-gradient-to-t from-gradient1 to-white"
        />
      )}
    </>
  );
};

export default CertificationSamples;
