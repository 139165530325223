import React from "react";
import Layout from "components/layout";
import GetInTouch from "components/getInTouch";
import HeroBanner from "../HeroBanner/index";
import ServiceClients from "../ServiceClients/index";
import CertificationSamples from "../CertificationSamples/index";
import CertifiedComponent from "../CertifiedComponent/index";
import DoubleCol from "components/DoubleCol";
import ServiceStats from "components/ServiceStats";
import TranslationServiceGrid from "components/TranslationServiceGrid";
import FullWidthImage from "components/FullWidthImage";
import CaseStudyGrid from "components/CaseStudyGrid";
import SupportedLanguages from "components/SupportedLanguages";
import GlobalCta from "components/GlobalCta";
import SEO from "components/seo";
import FAQ from "components/FAQ";
import Reviews from "components/Reviews";
import BreadCrumbComp from "components/BreadCrumbComp";
import DoubleCols from "components/DoubleCol/doublecol";

function ServicePages(props) {
  const pageData = props.pageContext.data;

  return (
    <Layout>
      <SEO
        title={pageData.meta.meta_title}
        description={pageData.meta.meta_description}
        keywords={pageData.meta.meta_keywords}
        slug={pageData.slug}
        scehema = {pageData.meta.schema_markup_text}
      />
      {pageData.widgets &&
        pageData.widgets.map(({ widget, data }, i) => {
          if (widget === "block_hero_banner") {
            return (
              <div key={i}>
                <HeroBanner {...data} />
                {pageData.meta.breadcrumbs && (
                  <BreadCrumbComp data={pageData.meta.breadcrumbs} />
                )}
              </div>
            );
          } else if (widget === "block_service_client") {
            return (
              <div key={i}>
                <ServiceClients data={data} />
              </div>
            );
          } else if (widget === "block_certification_samples") {
            return (
              <div key={i}>
                <CertificationSamples data={data} />
              </div>
            );
          } else if (widget === "block_certification_samples_1") {
            return (
              <div key={i}>
                <CertificationSamples data={data} />
              </div>
            );
          } else if (widget === "block_rating") {
            return (
              <div key={i}>
                <Reviews data={data} />
              </div>
            );
          } else if (widget === "block_certified_translations") {
            return (
              <div key={i}>
                <CertifiedComponent data={data} />
              </div>
            );
          } else if (widget === "block_certified_translations_1") {
            return (
              <div key={i}>
                <CertifiedComponent data={data} />
              </div>
            );
          } else if (widget === "block_certified_translations_2") {
            return (
              <div key={i}>
                <CertifiedComponent data={data} />
              </div>
            );
          } else if (widget === "block_certification_samples_2") {
            return (
              <div key={i}>
                <CertifiedComponent data={data} />
              </div>
            );
          } else if (widget === "block_two_column_content") {
            return (
              <div key={i}>
                <DoubleCol data={data} />
              </div>
            );
          } else if (widget === "block_two_column_content_1") {
            return (
              <div key={i}>
                <DoubleCol data={data} />
              </div>
            );
          } else if (widget === "block_two_column_content_3") {
            return (
              <div key={i}>
                <DoubleCols data={data} />
              </div>
            );
          } else if (widget === "block_service_stats") {
            return (
              <div key={i}>
                <ServiceStats data={data} />
              </div>
            );
          } else if (widget === "block_three_column_list") {
            return (
              <div key={i}>
                <TranslationServiceGrid data={data} />
              </div>
            );
          } else if (widget === "block_three_column_list_1") {
            return (
              <div key={i}>
                <TranslationServiceGrid data={data} />
              </div>
            );
          } else if (widget === "block_three_column_list_2") {
            return (
              <div key={i}>
                <TranslationServiceGrid data={data} />
              </div>
            );
          } else if (widget === "block_three_column_list_3") {
            return (
              <div key={i}>
                <TranslationServiceGrid data={data} />
              </div>
            );
          } else if (widget === "block_full_width_image") {
            return (
              <div key={i}>
                <FullWidthImage data={data} />
              </div>
            );
          } else if (widget === "block_service_contact_us") {
            return (
              <div key={i}>
                <CaseStudyGrid data={data} />
              </div>
            );
          } else if (widget === "block_supported_languages") {
            return (
              <div key={i}>
                <SupportedLanguages data={data} />
              </div>
            );
          } else if (widget === "block_contact_us") {
            return (
              <div key={i}>
                <GlobalCta data={data} />
              </div>
            );
          } else if (widget === "block_faq") {
            return (
              <div key={i}>
                <FAQ data={data} />
              </div>
            );
          } else if (widget === "block_certified_translation") {
            return (
              <div key={i}>
                <CertifiedComponent data={data} />
              </div>
            );
          } else if (widget === "block_two_column_content_2") {
            return (
              <div key={i}>
                <DoubleCol data={data} />
              </div>
            );
          }
          return false;
        })}
      <GetInTouch />
    </Layout>
  );
}

export default ServicePages;
